import graphqlQuery from './graphqlQuery'
import SITES_QUERY from 'graphql/sites.query'

const getSites = async () => {
  const sites = await graphqlQuery(SITES_QUERY, 'WP_ROOT')
  if (!sites || !sites.sites) return null

  return sites.sites
}

export default getSites
