import { gql } from "@apollo/client";

const SITES_QUERY = gql`
  query SITES_QUERY {
    sites {
      id
      name
      path
      units
      shoeSizeUnits
      image1
      image2
    }
  }
`

export default SITES_QUERY
